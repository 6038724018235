<template>
    <div>

        <div class="pititle">
            <!-- TODO 这里的搜索部分可以写成组件的 -->
            <div class="zhidingsearch">
                <el-form>
                    <div class="litop">
                        <el-row>
                            <el-col :span="5">
                                <div class="grid-content bg-purple">
                                    <el-form-item label="标准名称">
                                        <el-input v-model="proName" placeholder="请输入标准名称" clearable
                                            @keyup.enter.native="search()"></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col v-if="this.isAdmin == 1" :span="5">
                                <div class="grid-content bg-purple-light">
                                    <el-form-item label="编制单位">
                                        <el-input v-model="firstUnit" placeholder="请输入编制单位" clearable
                                            @keyup.enter.native="search()"></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div class="grid-content bg-purple">
                                    <el-form-item label="标准类型">
                                        <el-select v-model="biaozhunType" placeholder="标准类型" clearable
                                            @keyup.enter.native="search()">
                                            <el-option label="标准" :value="1"></el-option>
                                            <el-option label="标准设计" :value="2"></el-option>
                                            <!-- <el-option label="推荐行技术规程" :value="3"></el-option> -->
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="5">
                                <div class="grid-content bg-purple-light">
                                    <el-form-item label="制定类型">
                                        <el-select v-model="zhidingType" placeholder="制定类型" clearable
                                            @keyup.enter.native="search()">
                                            <el-option label="制定" :value="1"></el-option>
                                            <el-option label="修订" :value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col v-if="this.isAdmin == 1" :span="5">
                                <div class="grid-content bg-purple">
                                    <el-form-item label="标签检索">
                                        <el-select v-model="labelkey" placeholder="标签检索" clearable multiple
                                            @focus="labelDataList()" @keyup.enter.native="search()"
                                            @change="markChange">
                                            <el-option v-for="(item, index) in labelList" :key="index"
                                                :value="item.name"></el-option>
                                            <!-- <el-option label="标签3" :value="5"></el-option> -->
                                        </el-select>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="4">
                                <div class="grid-content bg-purple" style="display: flex; flex-direction: row-reverse">
                                    <el-button type="info" @click="qingkong" style="margin-left: 10px">清空</el-button>
                                    <el-button type="primary" @click="search">查询</el-button>
                                </div>
                            </el-col>
                        </el-row>

                    </div>
                </el-form>
            </div>
            <div class="piconetn">
                <div v-if="this.isAdmin == 1" style="margin-bottom: 20px; float: right">
                    <el-button type="success" @click="lixiang">通过</el-button>
                    <el-button type="danger" @click="bulixiang">退回</el-button>
                    <el-button type="primary" @click="exportExcel">导出表单</el-button>
                </div>
                <div v-else-if="this.isAdmin == 3" style="
            margin-bottom: 10px;
            display: flex;
            flex-direction: row-reverse;
          ">
                    <router-link to="/user_project/addImformation">
                        <el-button type="danger" tyle="height:45px;" icon="el-icon-plus">新增申请</el-button>
                    </router-link>
                </div>

                <!-- 表格 -->
                <div class="pitable">
                    <el-table :data="tableData" :row-class-name="tabRowClassName" style="width: 100%"
                        @selection-change="selected_change"
                        :header-cell-style="{ background: '#3082E2', color: '#FFF' }" :row-key="rowId">
                        <el-table-column reserve-selection type="selection" width="55">
                        </el-table-column>
                        <!-- <el-table-column label="序号" type="index"> </el-table-column> -->
                        <el-table-column label="ID" prop="id" align="left" width="55"></el-table-column>
                        <el-table-column v-if="this.isAdmin == 1" label="标准名称" prop="proName">
                            <template slot-scope="{ row }">
                                <router-link :to="gotoStep(row)" class="linkto">
                                    <span>{{ row.proName }}</span>
                                </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column v-else label="标准名称" prop="proName">
                            <template slot-scope="{ row }">
                                <router-link v-if="row.status == 1.15 || row.status == 1.16"
                                    :to="'/user_project/addImformation?id=' + row.id" class="linkto">
                                    <span>{{ row.proName }}</span>
                                </router-link>
                                <router-link v-else :to="'/user_project/detail?id=' + row.id" class="linkto">
                                    <span>{{ row.proName }}</span>
                                </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column label="标准类型" align="center" width="130px">
                            <template slot-scope="{ row }">
                                <span v-if="row.biaozhunType == 1">标准</span>
                                <span v-if="row.biaozhunType == 2">标准设计</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="制定类型" align="center" width="130px">
                            <template slot-scope="{ row }">
                                <span v-if="row.zhidingType == 1">制定</span>
                                <span v-if="row.zhidingType == 2">修订</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" align="left">
                            <template slot-scope="{ row }">
                                <el-tag size="mini"
                                    :style="{ 'background-color': getStatusColor(row.status), 'color': 'white' }">{{
                                        getStatusText(row.status) }}</el-tag>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="this.isAdmin == 1" label="企业名称" prop="firstUnit" align="center">
                        </el-table-column>
                        <!-- <el-table-column v-if="this.isAdmin == 1" label="立项编号" prop="number" align="center">
                        </el-table-column> -->
                        <el-table-column v-if="this.isAdmin == 1" label="认证号" prop="" align="center">
                        </el-table-column>
                        <el-table-column v-if="this.isAdmin == 1" label="公告号" prop="" align="center">
                        </el-table-column>
                        <el-table-column v-if="this.isAdmin == 1" label="审核专家" prop="expert" align="center">
                            <template slot-scope="{ row }">
                                <div class="label_list">
                                    <div v-for="(item, index) in row.expert" :key="index" class="btns">
                                        <el-tag size="mini" style="color:white;background-color:green"
                                            v-if="item.expert != null">{{ item.expert }}</el-tag>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="this.isAdmin == 1" label="标签" prop="batch" align="center" width="200px">
                            <template slot-scope="{ row }">
                                <div class="label_list">
                                    <div v-for="(item, index) in row.label" :key="index" class="btns">
                                        <el-tag size="mini" style="color:white;background-color:black"
                                            v-if="item.label != null">{{ item.label }}</el-tag>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="this.isAdmin == 1" label="操作" align="center" width="200px">
                            <template slot-scope="{ row }">
                                <el-tooltip class="item" effect="dark" content="添加标签" placement="top">
                                    <i class="el-icon-collection-tag" @click="addLabel(row.id)"
                                        style="color:#406dff"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="分配专家" placement="top">
                                    <i class="el-icon-user" @click="setexports(row.id)"
                                        style="color: green;margin-left: 10px;"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i class="el-icon-delete" @click="del(row.id)"
                                        style="color: red;margin-left: 10px;"></i>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <!-- 专家用户 -->

                        <!-- 企业用户的操作 -->
                        <el-table-column v-else-if="this.isAdmin == 4" label="操作" align="center" width="200px">
                            <template slot-scope="{ row }">
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i class="el-icon-delete" @click="del(row.id)"
                                        style="color: red;margin-left: 20px;"></i>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 分页 -->
                <div class="pageTotal">
                    <div class="block">
                        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="label_show" title="新增标签" width="40%">
            <el-form>
                <el-form-item label="标签名称" class="label_input">
                    <el-select v-model="labelSubitem" placeholder="标签检索" clearable multiple @focus="labelDataList()"
                        @change="markItemChange">
                        <el-option v-for="(item, index) in labelList" :key="index" :value="item.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="label_show = false">取 消</el-button>
                <el-button type="primary" @click="label_sure">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="exportshow" title="分配专家" width="40%">
            <el-form>
                <el-form-item label="专家姓名" class="label_input">
                    <el-select v-model="userstabledata" placeholder="专家检索" clearable multiple
                        @focus="fetchUserList(4, 2)" @change="markuserItemChange">
                        <el-option v-for="(item, index) in exportList" :key="index" :value="item.name"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="exportshow = false">取 消</el-button>
                <el-button type="primary" @click="addexports">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    addBiaozhun,
    delformalPend,
    projectAddLabel,
    labelSelectData,
    userInfoApi,
    addProjectExpert
} from "@api/project";

import { enterpriseStandardList, statusList } from "@api/enterprise";
import { formatDate } from "@utils/filters";
import { generateGUID } from "@utils/tools";
import { getUserList } from "@api/user";
export default {
    filters: {
        formatDate
    },
    data() {
        return {
            exportrowid: '',
            exportList: [],
            markuserIds: [],
            userstabledata: [],//专家列表
            // 专家弹窗
            exportshow: false,
            // 管理员判断
            isAdmin: 1,
            //选中的表格数据
            selectedProjects: [],
            form: {},
            page: 1,
            limit: 10,
            totalCount: 0,
            totalPage: 1,
            tableData: [],
            status: "",
            token: "",
            firstUnit: "",
            proName: "",
            zhidingType: "",
            date: "",
            number: "",
            batch: "",
            biaozhunType: "",
            batch_show: false,

            label_show: false,
            labelSubitem: "",
            labelList: [],
            markIds: [],
            labelkey: "",
            markData: []
        }
    },
    mounted() {
        this.token = this.$store.state.token; //获取cookie字符串
        this.getUserInfo();
        this.fetchStatusList();
        this.pendListData();
        // this.fetchUserList(4,2);
    },
    methods: {
        // 设置专家
        setexports(rowid) {
            this.exportshow = true;
            this.exportrowid = rowid;
        },
        //添加专家 
        addexports() {
            let params = {
                id: this.exportrowid,
                token: this.token,
                expert: String(this.markuserIds)
            }
            addProjectExpert(params).then((rec) => {
                this.$message({
                    showClose: true,
                    message: "设置专家成功",
                    type: "success",
                });
                this.exportshow = false;
            });

        },
        //获取管理员用户列表
        fetchUserList(cl, ty) {
            let param = {
                token: this.token,
                page: 1,
                limit: 100,
                class: cl,
                type: ty,
            };

            if (this.name) {
                param.name = this.name;
            }
            if (this.telephone) {
                param.telephone = this.telephone;
            }
            getUserList(param).then((res) => {
                this.exportList = res.data.data.filter(item => item.name != null);
                // console.log(this.exportList)
                // this.limit = Number(res.data.per_page)
                // this.page = res.data.current_page;
                // this.totalCount = res.data.total;
                // this.totalPage = res.data.last_page;
            });

        },
        // 用户列表修改
        markuserItemChange(val) {
            let tagList = val
            var filterdata = this.exportList.filter(item => tagList.some(x => x === item.name))
            this.markuserIds = filterdata.map(val => val.id)
        },
        // 获取用户权限
        getUserInfo() {
            let params = {
                token: this.token,
            };
            userInfoApi(params).then((res) => {
                this.isAdmin = res.data.type;
                if (this.isAdmin == 1) {
                    this.labelDataList()
                }
            });
        },
        // 获取状态
        fetchStatusList() {
            try {
                let param = {
                    page: this.page,
                    limit: 100,
                    token: this.token,
                };
                statusList(param)
                    .then((res) => {
                        this.statusMap = this.generateStatusMap(res.data.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (error) {
                console.error(error);
            }
        },
        generateStatusMap(statusl) {
            const statusMap = {};
            statusl.forEach(item => {
                statusMap[item.number] = { "rgb": item.rgb, "name": item.name }; // 将状态与颜色进行映射
            });
            return statusMap;
        },
        getStatusColor(status) {
            return this.statusMap[status].rgb; // 获取状态对应的颜色，如果不存在则返回空字符串
        },
        getStatusText(status) {
            return this.statusMap[status].name;
        },

        rowId(row) {
            return row.id;
        },
        tabRowClassName({ rowIndex }) {
            let index = rowIndex + 1;
            if (index % 2 == 0) {
                return "warning-row";
            }
        },
        pendListData() {
            let params = {
                limit: this.limit,
                page: this.page,
                token: this.token,
                proName: this.proName,
                firstUnit: this.firstUnit,
                zhidingType: this.zhidingType,
                biaozhunType: this.biaozhunType,
                date: this.date,
                batch: this.batch,
                label: this.markData
            }
            enterpriseStandardList(params).then((rec) => {
                this.tableData = rec.data;
                this.limit = rec.limit;
                this.page = rec.page;
                this.totalCount = rec.totalCount;
                this.totalPage = rec.totalPage;
            });
        },
        markChange(val) {
            this.markData = val
        },
        //搜索
        search() {
            this.page = 1;
            this.pendListData()
        },
        //清空
        qingkong() {
            this.firstUnit = ""
            this.proName = ""
            this.zhidingType = ""
            this.biaozhunType = ""
            this.labelkey = ''
            this.markData = []
            this.pendListData()
        },
        // 分页
        handleSizeChange(page) {
            this.limit = page;
            this.pendListData()
        },
        handleCurrentChange(val) {
            //console.log(`当前页: ${page}`)
            this.page = val;
            this.pendListData()
        },
        //表格多选
        selected_change(val) {
            this.selectedProjects = val;
            // console.log(this.selectedProjects);
        },
        gotoStep(row) {
            let url = "/formal/proDetailForZhongxin?id=" + row.id;
            return url;
        },
        // 导出excel表格
        exportExcel() {
            //导出表单
            let url = "https://api.gbservice.cn/api/standard/export?";
            let idarr = this.selectedProjects.map((item) => {
                return item.id;
            });
            let ids = idarr.toString();
            url += "token=" + this.$store.state.token;
            url += "&ids=" + ids;
            window.open(url, "blank");

        },
        // 立项按钮
        lixiang() {

            if (this.selectedProjects.length < 1) {
                this.$message({
                    showClose: true,
                    message: "请选择项目",
                    type: "warning",
                });
                return;
            }
            let sp = this.selectedProjects.find((item) => item.status != "1.20"); //!=4存在其他状态的项目
            if (sp != undefined) {

                this.$message({
                    showClose: true,
                    message: "请选择正确的项目立项",
                    type: "warning",
                });
                return;
            } else {
                // this.batch_show = true;
                let randomnumber = generateGUID()
                this.add_batch(randomnumber)
            }
        },
        // 添加立项状态
        add_batch(number) {
            for (let i = 0; i <= this.selectedProjects.length - 1; i++) {
                this.selectedProjects[i].status = 2.10; //修改状态
                this.selectedProjects[i].type = 1;
                this.selectedProjects[i].number = number;
                this.selectedProjects[i].token = this.token;
                addBiaozhun(this.selectedProjects[i]).then((rec) => {
                    this.$confirm('是否确认通过该标准?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$message({
                            showClose: true,
                            message: "项目已通过",
                            type: "success",
                        });
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '未通过'
                        });
                    });



                });
            }
        },
        // 不立项
        bulixiang() {
            if (this.selectedProjects.length < 1) {
                this.$message({
                    showClose: true,
                    message: "请选择项目",
                    type: "warning",
                });
                return;
            }
            let sp = this.selectedProjects.find((item) => item.status != 1.20); //!=4存在其他状态的项目
            if (sp != undefined) {
                this.$message({
                    showClose: true,
                    message: "请选择正确的项目操作",
                    type: "warning",
                });
                return;
            } else {
                for (let i = 0; i <= this.selectedProjects.length - 1; i++) {
                    this.selectedProjects[i].status = 1.30; //修改状态
                    this.selectedProjects[i].token = this.token;
                    addBiaozhun(this.selectedProjects[i]).then((rec) => {
                        // console.log(rec);
                        this.$message({
                            showClose: true,
                            message: "修改成功",
                            type: "success",
                        });
                    });
                }
            }
        },
        // 删除标准
        del(rowID) {
            this.$confirm("此操作将删除该项目, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                delformalPend({ id: rowID, token: this.token }).then((rec) => {
                    console.log(rec);
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                    setTimeout(() => {
                        this.$router.go(0);
                    }, 500);
                });
            });
        },
        markItemChange(val) {
            let tagList = val
            var filterdata = this.labelList.filter(item => tagList.some(x => x === item.name))
            this.markIds = filterdata.map(val => val.id)
        },
        //查询标签数据
        labelDataList() {
            labelSelectData({ token: this.token }).then((rec) => {
                this.labelList = rec.data.filter(item => item.name != null);
            });
        },
        addLabel(rowID) {
            this.label_show = true
            this.rowID = rowID
            this.labelSubitem = []
        },
        //新增标签确认
        label_sure() {
            let params = {
                id: this.rowID,
                token: this.$store.state.token,
                label: this.markIds
            }
            projectAddLabel(params).then((rec) => {

                if (rec.code == 200) {
                    this.$message({
                        showClose: true,
                        message: rec.message,
                        type: "success",
                    });
                    // this.formData.markList = [""]
                    this.label_show = false
                    this.pendListData()
                }
            });
            // }
        },
    }
}
</script>
<style scoped>
/deep/.el-button--primary {
    background-color: #3082e2;
    border-color: #3082e2;
}

/deep/.el-button--primary:hover {
    background-color: #409eff;
    border-color: #409eff;
}

/deep/.el-input__suffix {
    right: 43px;
    transition: all 0.3s;
    border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
    background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
    min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
    border-bottom: 0;
}

/deep/.el-form-item__label {
    font-family: Adobe Heiti Std;
    color: #2d2d2d;
}

/deep/ .litop .el-input {
    width: 245px;
}

/deep/ .litop .el-input__inner {
    width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
    font-size: 16px;
}

/deep/.el-table .warning-row {
    background: rgba(48, 130, 226, 0.1);
}

.pitable {
    /*height: 60vh;*/
}


.litop {
    margin: 20px 24px;
}

.litop span {
    /* color: #3086fb; */
    /* margin-left: 10px; */
    line-height: 40px;
}

.pageTotal {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
}

.prbtn {
    display: flex;
    flex-direction: row-reverse;
}

.linkto {
    text-decoration: none;
    color: #3082e2;
}

.linkto>span:hover {
    color: #84b5ec;
}

/* 新改 */
.zhidingsearch {
    background-color: #ffffff;
    padding: 1px 15px;
    margin: 20px 0 10px 0;
}

.piconetn {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 9px 1px #eeeeee;
}
</style>
